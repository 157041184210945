import React, {useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {GET_USER_WATCH_SETTINGS_QUERY, ME} from '../../../graphql/queries';
import {useMutation, useQuery} from '@apollo/react-hooks';
import SimpleLayout from '../../../components/layout_simple';
import Payment from '../../../components/pricing/payment';
import {RESEND_VERIFICATION_EMAIL} from '../../../graphql/mutations';
import {getUserToken, removePackageInfo} from '../../../helpers';
import './styles.scss';
import useHasFocus from '../../../hooks/useHasFocus';

const Checkout = () => {
  const [user, setUser] = useState(null as any);
  const {data: userData} = useQuery(ME, {
    fetchPolicy: 'no-cache',
  });
  const {data: hexowatchUserInfo} = useQuery(GET_USER_WATCH_SETTINGS_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const [selected, setSelected] = useState<any>(null);
  const [showPaypalMessage, setShowPaypalMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const focus = useHasFocus();

  const [receiveEmail, {loading}] = useMutation(RESEND_VERIFICATION_EMAIL, {
    onCompleted: (data: any) => handleConfirm(data),
  });

  useEffect(() => {
    if (localStorage.getItem('checkout-data')) setSelected(JSON.parse(localStorage.getItem('checkout-data') || ''));
    else if (!success) navigate('/pricing/');
  }, []);

  useEffect(() => {
    if (
      userData &&
      userData.User &&
      userData.User.get &&
      hexowatchUserInfo &&
      hexowatchUserInfo.UserWatchSettings &&
      hexowatchUserInfo.UserWatchSettings.get
    ) {
      setUser({
        ...userData.User.get,
        watch_paypal_subscription_id: hexowatchUserInfo.UserWatchSettings.get.paypal_subscription_id,
        watch_stripe_subscription_id: hexowatchUserInfo.UserWatchSettings.get.stripe_subscription_id,
      });
    }
  }, [userData, hexowatchUserInfo]);

  useEffect(() => {
    if (
      hexowatchUserInfo &&
      hexowatchUserInfo.UserWatchSettings &&
      hexowatchUserInfo.UserWatchSettings.get &&
      ((hexowatchUserInfo.UserWatchSettings.get.pricing_package_interval === 'LTD' && !selected?.ltd) ||
        (hexowatchUserInfo.UserWatchSettings.get.pricing_package !== 'FREE' &&
          hexowatchUserInfo.UserWatchSettings.get.pricing_package &&
          selected?.name === 'Pro' &&
          hexowatchUserInfo.UserWatchSettings.get.pricing_package !== 'STANDARD')) &&
      !success
    ) {
      navigate('/pricing/');
      removePackageInfo();
    } else if (!getUserToken()) {
      navigate('/login/');
    }
  }, [hexowatchUserInfo, selected, focus]);

  const handleConfirm = async (data: any): Promise<void> => {
    if (data.UserOps.resendVerification && !data.UserOps.resendVerification.error) {
      setMessage('success');
    } else if (
      data.UserOps.resendVerification.error &&
      data.UserOps.resendVerification.message === 'Please try few minutes later.'
    ) {
      setMessage(data.UserOps.resendVerification.message);
    } else {
      setMessage('error');
    }
  };

  return (
    <>
      {user?.id && !user.verified ? (
        <SimpleLayout isClickable>
          {loading || !user?.id ? (
            <div className="w-100 d-flex">
              <div className="spinner-border spinner-color m-auto" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : message !== 'error' ? (
            <p className="text-center p-2 verify-email-section">
              <div className="text-center max-width-420">
                <div className="text-center">
                  <p className="lets-verify">Now let’s verify your email</p>
                  Check <span className="email-blue">{user?.email}</span> to verify your account and get started.
                </div>
              </div>
              <p className="mt-2" style={{lineHeight: '25px'}}>
                <span className="click-here" onClick={() => receiveEmail()}>
                  Click here
                </span>{' '}
                to resend the confirmation email.
              </p>

              {message && (
                <p className={`text-${message === 'success' ? 'success' : 'danger'} center mt-3`}>
                  {message === 'success' ? 'Verification email successfully sent!' : message}
                </p>
              )}
            </p>
          ) : (
            <>{message === 'error' && <p className="text-danger text-center">Verification Failed!</p>}</>
          )}
        </SimpleLayout>
      ) : (
        <SimpleLayout noIllustration>
          <div className="checkout-page">
            {loading || !user?.id ? (
              <div className="w-100 d-flex">
                <div className="spinner-border spinner-color m-auto" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <>
                {selected && (
                  <Payment
                    id={selected.id}
                    paypal_id={selected.paypal_id}
                    count={1}
                    frequency={selected.frequency}
                    totalChecks={selected.totalChecks}
                    description={selected.description}
                    siteMapMonitor={selected.siteMapMonitor}
                    showPaypalMessage={showPaypalMessage}
                    setShowPaypalMessage={setShowPaypalMessage}
                    price={selected.price}
                    isTrial={selected.isTrial}
                    name={selected.name}
                    setSuccess={setSuccess}
                    success={success}
                    annual={selected.interval === 'annual'}
                    user={user}
                    ltd={selected.ltd}
                    ltdUrl={selected.ltdUrl}
                  />
                )}
              </>
            )}
          </div>
        </SimpleLayout>
      )}
    </>
  );
};

export default Checkout;
